import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import PendingTasksPopover from "./PendingTasksPopover";
import classNames from "classnames";

import GoogleDrive from "../images/icons/google-drive.svg";
import Confluence from "../images/icons/confluence.svg";
import OneDrive from "../images/icons/one-drive.svg";
import SharePoint from "../images/icons/sharepoint.svg";
import Zendesk from "../images/icons/zendesk.svg";

export function MascotDataHeader() {
  const { mascotId } = useParams();
  const history = useHistory();
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path ? "active" : "";
  };

  return (
    <>
      <div className="edit-mascot-header">
        <h1>Training Data</h1>
      </div>

      <div className="content-wrapper full-width">
        <p>
          Provide content that will make your mascot a subject matter expert. Ensure the content is comprehensive and can be
          understood if read by a human. <br />
          Processing times vary depending on file size and amount of files. <span className="warning">Maximum size per file: 50MB</span>
        </p>

        <p className="meta small">
          <i className="icon-lock"></i> Your data is end-to-end encrypted, and never shared. Read our{" "}
          <a href="https://wiseox.com/legal/privacy" target="_blank" rel="noreferrer">
            Privacy Notice
          </a>
          .
        </p>
      </div>

      <div className="edit-mascot-tabbar">
        <div
          className={classNames("tabbar-item", isActive(`/mascot/${mascotId}/edit/data`))}
          onClick={() => history.push(`/mascot/${mascotId}/edit/data`)}
        >
          <i className="icon-upload"></i>
          <span>Uploaded Files</span>
        </div>

        <div
          className={classNames("tabbar-item", isActive(`/mascot/${mascotId}/edit/data/web`))}
          onClick={() => history.push(`/mascot/${mascotId}/edit/data/web`)}
        >
          <i className="icon-globe"></i>
          <span>Web URLS</span>
        </div>

        <div
          className={classNames("tabbar-item", isActive(`/mascot/${mascotId}/edit/data/google-drive`))}
          onClick={() => history.push(`/mascot/${mascotId}/edit/data/google-drive`)}
        >
          <img src={GoogleDrive} alt={GoogleDrive} />
          <span>Google Drive</span>
        </div>

        <div
          className={classNames("tabbar-item", isActive(`/mascot/${mascotId}/edit/data/confluence`))}
          onClick={() => history.push(`/mascot/${mascotId}/edit/data/confluence`)}
        >
          <img src={Confluence} alt={Confluence} />
          <span>Confluence</span>
        </div>

        <div
          className={classNames("tabbar-item", isActive(`/mascot/${mascotId}/edit/data/zendesk`))}
          onClick={() => history.push(`/mascot/${mascotId}/edit/data/zendesk`)}>
          <img src={Zendesk} alt={Zendesk} />
          <span>Zendesk</span>
        </div>

        <div
          className={classNames("tabbar-item", isActive(`/mascot/${mascotId}/edit/data/one-drive`))}
          onClick={() => history.push(`/mascot/${mascotId}/edit/data/one-drive`)}
        >
          <img src={OneDrive} alt={OneDrive} />
          <span>OneDrive <span className="meta small">(Personal)</span></span>
        </div>

        <div
          className={classNames("tabbar-item", isActive(`/mascot/${mascotId}/edit/data/sharepoint`))}
          onClick={() => history.push(`/mascot/${mascotId}/edit/data/sharepoint`)}
        >
          <div className="combo-icons">
            <img src={OneDrive} alt={OneDrive} />
            <span>{"+"}</span>
            <img src={SharePoint} alt={SharePoint} />
          </div>
          <span>OneDrive Business <span className="meta small">(Includes Sharepoint)</span></span>
        </div>

        <div
          className={classNames("tabbar-item", isActive(`/mascot/${mascotId}/edit/data/email`))}
          onClick={() => history.push(`/mascot/${mascotId}/edit/data/email`)}
        >
          <i className="icon-email"></i>
          <span>Email</span>
        </div>
      </div>

      <PendingTasksPopover></PendingTasksPopover>
    </>
  );
}

import React, { useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

// Components
import FileInput from "../ui/FileInput";
import Traitsfield from "../components/Traitsfield";
import MascotImage from "../components/MascotImage";

// Services
import { client } from "../services/api";
import { useEditMascot } from "../hooks/useEditMascot";
import { toast } from "react-toastify";

const sheetSchema = Yup.object().shape({
  name: Yup.string().trim().required("Name required"),
  description: Yup.string().trim().required("Description required"),
});

export default function EditMascotInfo() {
  const { mascotDraft, saveDraft, mascot } = useEditMascot();

  const traits = [
    "Funny",
    "Agreeable",
    "Serious",
    "Cheerful",
    "Creative",
    "Empathetic",
    "Helpful",
    "Enthusiastic",
    "Logical",
    "Optimistic",
    "Witty",
    "Charismatic",
    "Collaborative",
    "Friendly",
    "Precise",
    "Polite",
    "Honest",
    "Objective",
    "Sarcastic",
  ].map((t) => ({ name: t }));
  const imagePreview = useRef();

  const handleChangeMascot = (value) => {
    const mascot = {
      ...mascotDraft,
      ...value,
    };

    saveDraft(mascot);
  };

  const { handleChange, values, setFieldValue, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...mascotDraft,
      name: mascotDraft.name || "",
      description: mascotDraft.description || "",
      language: mascotDraft.language || "en-US",
      traits: mascotDraft.traits || [],
      image: mascotDraft.image || null,
    },
    validationSchema: sheetSchema,
    validate: handleChangeMascot,
  });

  const handleTraitsChange = (items) => {
    setFieldValue("traits", items);
  };

  const handleImageChange = async (event) => {
    if (event.currentTarget.files.length > 0) {
      const blob = URL.createObjectURL(event.currentTarget.files[0]);

      const data = new FormData();
      data.append("file", event.currentTarget.files[0]);
      try {
        const res = await client.updateMascotImage(mascot._id, data);
        if (res.ok) {
          imagePreview.current.src = blob;
          const mascot = {
            ...mascotDraft,
            image: res.data.image,
          };
          saveDraft(mascot);
        } else {
          toast.error("Error uploading the image");
        }
      } catch (e) {
        toast.error("Error uploading the image");
      }
    }
  };

  return (
    <div>
      <div className="edit-mascot-header">
        <h1>Mascot Persona</h1>
      </div>

      <div className="content-wrapper full-width col-2">
        <div className="content-col">
          <div className="input-group">
            <label>Mascot Name</label>
            <input type="text" name="name" value={values.name} onChange={handleChange} placeholder="e.g. ZipBot" />
            {errors.name && touched.name ? <div className="error">{errors.name}</div> : null}
          </div>

          <div className="input-group">
            <label>Description</label>
            <input
              type="text"
              name="description"
              value={values.description}
              onChange={handleChange}
              placeholder="Employee chat bot"
            />
            {errors.description && touched.description ? <div className="error">{errors.description}</div> : null}
          </div>

          <div className="input-group">
            <label>Personality Traits (enter up to 2)</label>
            {mascotDraft.traits && (
              <Traitsfield traits={traits} value={mascotDraft.traits ? mascotDraft.traits : []} onChange={handleTraitsChange} />
            )}
          </div>

          <div className="input-group">
            <label>Mascot Language</label>
            <select
              name="language"
              value={values.language || "en-US"}
              onChange={(e) => setFieldValue("language", e.target.value)}
            >
              <option value="en-US">English (US)</option>
              <option value="en-GB">English (UK)</option>
              <option value="en-AU">English (AU)</option>
              <option value="es">Spanish</option>
              <option value="pt">Portuguese</option>
              <option value="zh">Chinese</option>
              <option value="fr">French</option>
              <option value="it">Italian</option>
              <option value="de">German</option>
              <option value="nl">Dutch</option>
              <option value="hi">Hindi</option>
              <option value="ar">Arabic</option>
              <option value="ru">Russian</option>
            </select>
          </div>

        </div>

        <div className="content-col">
          <div className="preview-window square">
            <div className="preview-content">
              <span className="meta">Mascot Avatar</span>

              <FileInput
                formats={[".png", ".svg", ".jpg", ".jpeg", ".webp", ".gif"]}
                label="Upload Image"
                onChange={handleImageChange}
              ></FileInput>

              <div className="mascot-image-preview">
                <MascotImage mascot={mascotDraft} ref={imagePreview}></MascotImage>
                <p>Image must be 1:1 ratio (square), and cannot be larger than 5mb.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

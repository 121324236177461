import React from "react";
import ReactDOM from "react-dom";
import './i18n';
import App from "./App";

// Providers
import { BrowserRouter } from "react-router-dom";
import { ProvideAuth } from "./hooks/useAuth";
import { SocketProvider } from "./hooks/ws";
import { ProvideMascot } from "./hooks/useMascot";
import { ProvideTasks } from "./hooks/useTasks";
import { ProvideExtension } from "./hooks/useExtension";

ReactDOM.render(
  <BrowserRouter>
    <ProvideAuth>
      <ProvideMascot>
        <SocketProvider>
          <ProvideTasks>
            <ProvideExtension>
              <App />
            </ProvideExtension>
          </ProvideTasks>
        </SocketProvider>
      </ProvideMascot>
    </ProvideAuth>
  </BrowserRouter>,
  document.getElementById("root")
);

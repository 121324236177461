import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { MascotDataHeader } from "../components/MascotDataHeader";
import { ListDataFile } from "../components/ListDataFile";
import { useTasks } from "../hooks/useTasks";
import { useEditMascot } from "../hooks/useEditMascot";

export default function EditMascotDataEmail() {
  const { embeddingTask } = useTasks();
  const { mascotId } = useParams();
  const { mascot } = useEditMascot();
  const [emailGroups, setEmailGroups] = useState([]);

  const copyToClipboard = () => {
    toast.promise(navigator.clipboard.writeText(`${mascotId}@mascot.wiseox.ai`), {
      success: "Copied to clipboard",
      error: "Error copying to clipboard",
    });
  }

  useEffect(() => {
    setEmailGroups(mascot?.data?.uploads?.filter((doc) => doc.type === "email")
      .map((emailDoc) => {
        const attachments = mascot?.data?.uploads?.filter(
          (a) => a.type === "email_attachment" && a.email_id === emailDoc.uploadId
        );
        return {
          ...emailDoc,
          attachments,
        };
      }).reverse() || []);
  }, [mascot]);

  return (
    <div>
      <MascotDataHeader></MascotDataHeader>

      <div className="data-pane full-width">
        <h3>
          <i className="icon-email"></i> Train by Email
        </h3>

        <p>
          You can send or forward email to your mascot to train it on the contents of the email.<br />
          Send email to this address: <code className="clickable" onClick={() => copyToClipboard()}>{mascotId}@mascot.wiseox.ai</code>
        </p>

        <p className="meta warning">Note: You will need to publish your mascot when you see the emails appear here.</p>

        {emailGroups.length > 0 && (
          <ListDataFile
            title="Received Emails"
            searchLabel="Search emails"
            disabled={!!embeddingTask}
            showFilterType={false}
            grouped={true}
            files={ emailGroups }
          ></ListDataFile>
        )}
      </div>
    </div>
  );
}

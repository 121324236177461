import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enUS from './locales/en-US.json';
import enGB from './locales/en-GB.json';
import enAU from './locales/en-AU.json';
import es from './locales/es.json';
import pt from './locales/pt.json';
import zh from './locales/zh.json';
import fr from './locales/fr.json';
import it from './locales/it.json';
import de from './locales/de.json';
import nl from './locales/nl.json';
import hi from './locales/hi.json';
import ar from './locales/ar.json';
import ru from './locales/ru.json';

const resources = {
  'en-US': { translation: enUS },
  'en-GB': { translation: enGB },
  'en-AU': { translation: enAU },
  es: { translation: es },
  pt: { translation: pt },
  zh: { translation: zh },
  fr: { translation: fr },
  it: { translation: it },
  de: { translation: de },
  nl: { translation: nl },
  hi: { translation: hi },
  ar: { translation: ar },
  ru: { translation: ru },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en-US',
    supportedLngs: Object.keys(resources),
    detection: {
      order: ['localStorage', 'navigator', 'htmlTag'],
      caches: ['localStorage'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

i18n.on('languageChanged', (lng) => {
  document.documentElement.lang = lng;
});

export default i18n;

import React, { useEffect, useState, useCallback } from "react";
import Header from "../components/Header";
import Modal from "../components/Modal";
import { client } from "../services/api";
import { useAuth } from "../hooks/useAuth";
import * as Yup from "yup";

const sheetSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email Address is required")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Please enter a valid email address"),
});

export default function Administrators() {
  const { currentUser, currentOrganization, updateOrganization } = useAuth();
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [inviteEmail, setInviteEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [invites, setInvites] = useState([]);
  const [owner, setOwner] = useState({});

  useEffect(() => {
    if (!currentOrganization._id) return;
    getInvited(currentOrganization);
    if (currentOrganization.admins) {
      setOwner(currentOrganization.admins.find((a) => a._id === currentOrganization.owner._id));
    }
  }, [currentOrganization]);

  const handleRemoveAdmin = async (admin_id) => {
    const _org = { ...currentOrganization, admins: currentOrganization.admins.filter((a) => a._id !== admin_id) };
    await client.updateOrganization(_org);
    updateOrganization(_org);
  };

  const handleRemoveInvite = async (inviteId) => {
    await client.removeInvite(currentOrganization._id, inviteId);
    getInvited(currentOrganization);
  };

  const sendInvite = useCallback(async () => {
    setErrorMessage("");
    if (currentOrganization.admins.map((a) => a.email).indexOf(inviteEmail.toLowerCase()) !== -1) {
      return setErrorMessage("This user is already an administrator");
    }
    if (invites.map((a) => a.email).indexOf(inviteEmail.toLowerCase()) !== -1) {
      return setErrorMessage("User already invited");
    }

    try {
      sheetSchema.validateSync({ email: inviteEmail });
      const res = await client.inviteUser(currentOrganization._id, { userEmail: inviteEmail });
      if (res.ok) {
        setInviteEmail("");
        setInviteModalOpen(false);
        getInvited(currentOrganization);
      } else {
        setErrorMessage(res.data.message);
      }
    } catch (e) {
      setErrorMessage(e.errors[0]);
    }
    // eslint-disable-next-line
  }, [currentOrganization, inviteEmail]);

  const getInvited = async (org) => {
    const resultInvite = await client.getInvites(org._id);
    resultInvite.ok && setInvites(resultInvite.data);
  };

  return (
    <div className="main-wrapper">
      <Header></Header>

      <div className="content-wrapper narrow">
        <div className="page-title">
          <h1>Administrators</h1>

          <button className="action" onClick={() => setInviteModalOpen(true)}>
            Invite Admin
          </button>
        </div>

        <div className="alert org-alert">
          <span>
            Organization <b>{currentOrganization.name}</b>
          </span>
        </div>

        <div className="list dark">
          <ul>
            {owner &&
              currentOrganization.admins &&
              currentOrganization.admins.map((admin) => (
                <li className="list-item" key={admin._id}>
                  <div className="list-item-name">
                    {admin.firstName} {admin.lastName} ({admin.email})
                  </div>

                  <div className="list-item-table">
                    {admin._id !== owner._id && admin._id !== currentUser._id && (
                      // eslint-disable-next-line
                      <a className="meta" onClick={() => handleRemoveAdmin(admin._id)}>
                        Remove
                      </a>
                    )}

                    {admin._id === owner._id && <span className="meta light">Owner</span>}
                  </div>
                </li>
              ))}

            {owner &&
              invites &&
              invites.map((invite) => (
                <li className="list-item" key={invite._id}>
                  <div className="list-item-name">{invite.email}</div>

                  <div className="list-item-table">
                    <span className="meta light">Invited</span>
                    {/* eslint-disable-next-line */}
                    <a className="meta" onClick={() => handleRemoveInvite(invite._id)}>
                      Remove
                    </a>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>

      <Modal
        title="Invite Administrator"
        size="small"
        isOpen={inviteModalOpen}
        close={() => setInviteModalOpen(false)}
        action={
          <button className="action" onClick={sendInvite}>
            Invite
          </button>
        }
      >
        <div className="input-group">
          <label>Email Address</label>
          <input
            type="email"
            required
            name="inviteUserEmail"
            defaultValue=""
            onChange={(e) => setInviteEmail(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                sendInvite();
                e.stopPropagation();
                e.preventDefault();
              }
            }}
          />
          {errorMessage && <span className="error">{errorMessage}</span>}
        </div>
      </Modal>
    </div>
  );
}